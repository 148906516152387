import "./styles.css";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { DAppProvider, Mainnet, MetamaskConnector } from "@usedapp/core";
import { WalletConnectV2Connector } from "@usedapp/wallet-connect-v2-connector";
import React from "react";
import ReactDOM from "react-dom";
import { MoralisProvider } from "react-moralis";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import theme from "./theme";

const id = process.env.REACT_APP_APP_SERVER_ID ?? "";
const url = process.env.REACT_APP_APP_SERVER_URL ?? "";

const config = {
  readOnlyChainId: 137,
  connectors: {
    metamask: new MetamaskConnector(),
    walletConnectV2: new WalletConnectV2Connector({
      projectId: `${process.env.REACT_APP_WALLETCONNECT_KEY}`,
      chains: [Mainnet],
      rpcMap: {
        1: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFRA}`,
      },
    }),
  },
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ChakraProvider provider={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <BrowserRouter>
        <MoralisProvider serverUrl={url} appId={id}>
          <DAppProvider config={config}>
            <App />
          </DAppProvider>
        </MoralisProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
);
